import type { UseFetchOptions } from 'nuxt/app'
import { dynamicContentTransformer } from '~/utils/dynamicContentTransformer'

export function useStrapiSingleType<T, R = T>(
  url: string,
  options: UseFetchOptions<T, R> = {}
) {
  const config = useRuntimeConfig()
  const { strapiApiBaseURL, strapiApiKey } = config.public
  const staticMetrics = useStaticMetrics()

  const defaults: UseFetchOptions<T, R> = {
    baseURL: strapiApiBaseURL,
    // cache request
    key: url,

    headers: {
      Authorization: `Bearer ${strapiApiKey}`,
    },
  }

  const baseTransform = (data: T): R | T => {
    if (options.transform)
      return dynamicContentTransformer<R>(
        options.transform!(data) as R,
        staticMetrics
      )
    return dynamicContentTransformer<T>(data, staticMetrics) as T
  }

  const params = { ...defaults, ...options, transform: baseTransform }

  return useFetch(url, params)
}
